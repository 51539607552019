var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "userFollowListToFollow" },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v("PEOPLE YOU MIGHT ALSO WANT TO FOLLOW"),
      ]),
      _c("LoadingOverlay", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showLoader,
            expression: "showLoader",
          },
        ],
      }),
      _vm._l(this.followList, function (user, index) {
        return _c("UserFollowItem", {
          key: "FollowedUser" + user.id,
          attrs: { user: user, index: index, showBtn: _vm.showBtn },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }