<template>
  <div class="userFollowListToFollow">
    <div class="title">PEOPLE YOU MIGHT ALSO WANT TO FOLLOW</div>
    <LoadingOverlay v-show="showLoader" />
    <UserFollowItem v-for="(user, index) in this.followList"
      :key="'FollowedUser' + user.id"
      :user="user"
      :index="index"
      :showBtn="showBtn"
    />
  </div>
</template>

<script>
import UserFollowItem from './UserFollowItem.vue';
import LoadingOverlay from '../loaders/LoadingOverlay.vue';

export default {
  name: 'UserFollowListToFollow',
  components: {
    UserFollowItem,
    LoadingOverlay,
  },
  data() {
    return {
      showBtn: true,
      showLoader: false,
    };
  },
  async created() {
    if (this.followList.length === 0) {
      this.showLoader = true;
    }
    await this.$store.dispatch('userFollowStore/getOthersToFollowList');
    this.showLoader = false;
  },
  computed: {
    followList() {
      return this.$store.getters['userFollowStore/usersToFollowList'];
    },
  },
};
</script>

<style lang="scss" scoped>
.userFollowListToFollow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .title {
    font-weight: $bold;
    @include font-size(1.25rem);
    color: $LincolnGray;
    white-space: pre;
  }
}
.userFollowListToFollow > * {
  flex: 1 100%;
}
</style>
