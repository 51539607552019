<template>
  <div v-if="checkForButtonAndIndex" class="userFollowItem">
    <router-link @click.native="setProfileViewUser"
                 :to="{path: 'profile', query: { id: user.id }}"
                 class="img">
      <UserImageThumbnail :thumbnailAssetId="user.thumbnailAssetId"
                          />
    </router-link>
    <div class="middleCopy">
      <div class="name">
        <router-link @click.native="setProfileViewUser"
                     :to="{path: 'profile', query: { id: user.id }}">
          {{user.namePreferred}}
        </router-link>
      </div>
      <div class="title">{{position}}</div>
    </div>
    <div v-if="showBtn" @click="follow" class="button">
      <div class="plusIcon">
        <font-awesome-icon class="icon" :icon="['fas', 'user-plus']" size="2x" />
      </div>
    </div>
  </div>
</template>

<script>
import UserImageThumbnail from '../profile/UserImageThumbnail.vue';

export default {
  name: 'userFollowItem',
  props: ['user', 'index', 'showBtn'],
  components: {
    UserImageThumbnail,
  },
  methods: {
    async follow() {
      await this.$store.dispatch('userFollowStore/followUser', this.user.id);
      await this.$store.dispatch('userFollowStore/getBrandStory');

      this.$analytics.trackEventBtnPageWithMetaInfo('FollowUser',
        { userId: this.user.id });
    },
    setProfileViewUser() {
      this.$store.dispatch('profileViewStore/setProfile', this.user);

      this.$analytics.trackEventBtnPageWithMetaInfo('FollowGoToUser',
        { userId: this.user.id });
    },
  },
  computed: {
    checkForButtonAndIndex() {
      if (this.showBtn && this.index < 5) {
        return true;
      }
      if (!this.showBtn) {
        return true;
      }

      return false;
    },
    position() {
      if (this.user.nonStarsPosition) {
        return this.user.nonStarsPosition;
      }

      if (this.user.position) {
        return this.user.position.name;
      }

      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.userFollowItem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;

  .img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;

    border-radius: 50%;
    background-color: $LincolnGrayLighter;

    height: 60px;
    width: 60px;
  }

  .middleCopy {
    flex: 3;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;

    padding-left: 15px;

    .name {
      font-weight: bold;
      font-size: 20px;

      a {
        text-decoration: none;
        color: $LincolnGray;

        &:hover {
          color: $LincolnOrange;
        }
      }
    }
  }

  .button {
    margin-left: 25px;
    align-self: center;
    display: flex;

    .plusIcon {
      align-self: center;

      .icon {
        color: rgb(88, 94, 94);

        &:hover {
          color: $LincolnOrange;
        }
      }
    }
  }
}
</style>
